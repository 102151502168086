import { type ComponentProps, type ComponentType, type FunctionComponent } from 'react';

import { getAssetUrlsFromId } from '@atlassian/react-loosely-lazy-manifest';
import { postTask } from '@atlassian/react-async';

import { getConfig } from '../config';
import { PHASE } from '../constants';
import { displayNameFromId, hash, isNodeEnvironment } from '../utils';

import { createComponentClient } from './components/client';
import { createComponentServer } from './components/server';
import { PRIORITY, RETRY_DELAY, RETRY_FACTOR } from './constants';
import { createDeferred } from './deferred';
import { type ClientLoader, type Loader, type ServerLoader } from './loader';
import { preloadAsset } from './preload';
import { retry } from './retry';
import type { LazyComponent, LazyOptions, PreloadPriority } from './types';

export { PRIORITY };
export type { LazyOptions, LazyComponent, PreloadPriority };

function noop() {}

function deferToPhase(phase: (typeof PHASE)[keyof typeof PHASE]): Promise<void> {
	switch (phase) {
		case PHASE.PAINT:
			return postTask(noop, { priority: 'user-blocking' });
		case PHASE.AFTER_PAINT:
			return postTask(noop, { priority: 'user-visible' });
		case PHASE.LAZY:
			return postTask(noop, { priority: 'background' });
		default:
			return Promise.resolve();
	}
}

function lazyProxy<C extends ComponentType<any>>(
	loader: Loader<C>,
	{ defer = PHASE.PAINT, moduleId = '', ssr = true }: LazyOptions = {},
): LazyComponent<C> {
	const isServer = isNodeEnvironment();
	const dataLazyId = hash(moduleId);

	// eslint-disable-next-line @typescript-eslint/ban-types
	const LazyInternal: FunctionComponent<ComponentProps<C>> = isServer
		? createComponentServer({
				dataLazyId,
				defer,
				loader: loader as ServerLoader<C>,
				moduleId,
				ssr,
			})
		: createComponentClient({
				dataLazyId,
				defer,
				// We separate the preload from the retryable request, as we do not want the preload to contribute to retry
				// attempts, when the the loader fallback is used
				deferred: createDeferred({
					loader: () => {
						const { retry: maxAttempts, usePostTaskPhases } = getConfig();

						const deferredLoader = usePostTaskPhases
							? () => deferToPhase(defer).then(() => (loader as ClientLoader<C>)())
							: (loader as ClientLoader<C>);

						return retry(deferredLoader, {
							delay: RETRY_DELAY,
							factor: RETRY_FACTOR,
							maxAttempts,
						});
					},
					preload: loader as ClientLoader<C>,
				}),
				moduleId,
			});

	LazyInternal.displayName = `Lazy(${displayNameFromId(moduleId)})`;

	/**
	 * Allows getting module chunks urls
	 */
	const getAssetUrls = () => {
		const { manifest } = getConfig();

		return getAssetUrlsFromId(manifest, moduleId);
	};

	/**
	 * Allows imperatively preload/ prefetch the module chunk asset
	 */
	const preload = (priority?: PreloadPriority) => {
		const p = priority ?? (defer === PHASE.PAINT ? PRIORITY.HIGH : PRIORITY.LOW);

		return preloadAsset({ loader, moduleId, priority: p });
	};

	return Object.assign(LazyInternal, {
		getAssetUrls,
		preload,
	});
}

export const DEFAULT_OPTIONS: {
	[key: string]: { ssr: boolean; defer: number };
} = {
	lazyForPaint: { ssr: true, defer: PHASE.PAINT },
	lazyAfterPaint: { ssr: true, defer: PHASE.AFTER_PAINT },
	lazy: { ssr: false, defer: PHASE.LAZY },
};

export function lazyForPaint<C extends ComponentType<any>>(loader: Loader<C>, opts?: LazyOptions) {
	return lazyProxy<C>(loader, {
		...DEFAULT_OPTIONS.lazyForPaint,
		...(opts || {}),
	});
}

export function lazyAfterPaint<C extends ComponentType<any>>(
	loader: Loader<C>,
	opts?: LazyOptions,
) {
	return lazyProxy<C>(loader, {
		...DEFAULT_OPTIONS.lazyAfterPaint,
		...(opts || {}),
	});
}

export function lazy<C extends ComponentType<any>>(loader: Loader<C>, opts?: LazyOptions) {
	return lazyProxy<C>(loader, {
		...DEFAULT_OPTIONS.lazy,
		...(opts || {}),
	});
}

export type { ClientLoader, Loader, ServerLoader };
export { isLoaderError } from './errors';
